/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import ReportsLineChart from "layouts/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "layouts/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "pages/dashboard/data/reportsBarChartData";
import reportsLineChartData from "pages/dashboard/data/reportsLineChartData";
// import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getStatistics } from "redux/routes/dashboard";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { getAllProductsList } from "redux/routes/products";
import PosProduct from "./components/PosProduct";
import { DeleteForever } from "@mui/icons-material";
import { sendPOSInvoice } from "redux/routes/dashboard";
import { getCheckoutInfo } from "redux/routes/dashboard";
import { getPOSInvoices } from "redux/routes/dashboard";
import PosInvoice from "./components/PosInvoice";
import { getEssentialInfo } from "redux/routes/dashboard";
// import { getStatistics } from "redux/features/dashboardSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1500px",
  maxHeight: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Dashboard() {
  const [growth, setGrowth] = useState({
    labels: ["Loading"],
    datasets: {
      label: "Users",
      data: [0],
    },
  });
  const [visits, setVisits] = useState({
    labels: ["Loading"],
    datasets: {
      label: "Website Visits",
      data: [0],
    },
  });
  const [revenueData, setRevenueData] = useState({
    labels: ["Loading"],
    datasets: {
      label: "Revenue",
      data: [0],
    },
  });
  const [orders, setOrders] = useState(0);
  const [invoices, setInvoices] = useState(0);

  const init = async () => {
    getStatistics().then((res) => {
      if (res.success) {
        setStatistics(res.data);
        setGrowth(res.data.growth);
        setVisits(res.data.visits_data);
        setRevenueData(res.data.revenue_data);
      }
    });

    getEssentialInfo().then((res) => {
      if (res.success) {
        setOrders(res.data.orders);
        setInvoices(res.data.invoices);
      }
    });

    getAllProductsList().then((res) => {
      if (res.success) {
        setProducts(res.data);
      }
    });

    getCheckoutInfo().then((res) => {
      if (res.success) {
        setCheckoutInfo(res.data);
      }
    });

    getPOSInvoices().then((res) => {
      if (res.success) {
        setPosInvoices(res.data);
      }
    });
  };

  useEffect(() => {
    init();
  }, []);
  const [statistics, setStatistics] = useState([]);

  //POS
  const [posError, setPosError] = useState("");
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [sentInvoice, setSentInvoice] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [requestReview, setRequestReview] = useState(false);
  const [client, setClient] = useState({
    phone: "",
    email: "",
    name: "",
    send: true,
  });
  const [deliveryLoc, setDeliveryLoc] = useState({
    county: "",
    subcounty: "",
    courier: "",
    endpoint: "",
    price: 0,
    loc: {},
  });
  const [active, setActive] = useState("new");
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkoutInfo, setCheckoutInfo] = useState({ deliveryLocations: [] });
  const [posInvoices, setPosInvoices] = useState([]);
  const addSelected = (prod) => {
    let exists = false;
    let updated = selected.map((p) => {
      if (
        p._id === prod._id &&
        prod.color === p.color &&
        p.size === prod.size &&
        p.option === prod.option &&
        p.price === prod.price
      ) {
        p.amount++;
        exists = true;
      }
      return p;
    });

    if (!exists) {
      prod.amount = 1;
      updated.push(prod);
    }
    setSelected(updated);
  };
  const removeSelected = (idx) => {
    if (selected.length > idx) {
      if (selected[idx].amount > 1) {
        let update = selected.map((p, id) => {
          if (id === idx) {
            p.amount--;
          }
          return p;
        });
        setSelected(update);
      } else {
        let update = selected.filter((p, id) => id !== idx);
        setSelected(update);
      }
    }
  };
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setPosError("");
    let total = 0;
    selected.map((p) => {
      total += p.price * p.amount;
    });
    setTotalPrice(total);
  }, [selected]);
  const sendInvoice = async () => {
    setPosError("");
    if (selected.length === 0) {
      setPosError("No items have been selected");
      return;
    }
    if (sendingInvoice) return true;
    if (!client.phone) {
      setPosError("Provide the client phone number");
      return;
    }
    if (deliveryLoc.price < 0) {
      setPosError("Delivery fee cannot be negative");
      return;
    }
    setSendingInvoice(true);
    let payload = {
      client,
      selected,
      deliveryLoc,
      totalPrice: Number(totalPrice) + Number(deliveryLoc.price),
      delivered,
      requestReview,
    };
    let res = await sendPOSInvoice(payload);
    if (res.success) {
      setSentInvoice(true);
      setSelected([]);
      setDeliveryLoc({
        county: "",
        subcounty: "",
        courier: "",
        endpoint: "",
        price: 0,
        loc: {},
      });
      setClient({
        phone: "",
        email: "",
        send: true,
      });
      setPosInvoices([...posInvoices, res.data]);
      setTimeout(() => {
        setSentInvoice(false);
      }, 2000);
    } else {
      setPosError("Unable to send invoice. Kindly refresh the page and try again");
    }
    setSendingInvoice(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark", */}
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{
                textAlign: "center",
                padding: "10px",
                borderRadius: "7px",
                cursor: "pointer",
                color: "white",
              }}
              variant="gradient"
              bgColor="secondary"
              onClick={() => setOpenModal(true)}
            >
              Point Of Sale
            </MDBox>
            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDBox sx={style}>
                <MDTypography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  Point Of Sale
                </MDTypography>
                <MDBox display="flex" justifyContent="space-evenly">
                  <MDTypography
                    style={{
                      borderBottom: active === "new" ? "solid 2px dodgerblue" : "solid 2px grey",
                      color: active === "new" ? "dodgerblue" : "grey",
                      width: "45%",
                      textAlign: "center",
                      fontSize: "13pt",
                      cursor: "pointer",
                    }}
                    onClick={() => setActive("new")}
                  >
                    New
                  </MDTypography>
                  <MDTypography
                    style={{
                      borderBottom:
                        active === "pending" ? "solid 2px dodgerblue" : "solid 2px grey",
                      color: active === "pending" ? "dodgerblue" : "grey",
                      width: "45%",
                      textAlign: "center",
                      fontSize: "13pt",
                      cursor: "pointer",
                    }}
                    onClick={() => setActive("pending")}
                  >
                    Pending
                    {" (" + posInvoices.filter((inv) => inv.status === "INVOICED").length + ")"}
                  </MDTypography>
                </MDBox>
                {active === "new" && (
                  <Grid container spacing={3} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                          borderRadius: "7px",
                        }}
                      >
                        {products.map((prd) => {
                          return (
                            <PosProduct product={prd} addSelected={addSelected} key={prd._id} />
                          );
                        })}
                      </MDBox>
                      <MDTypography sx={{ fontSize: "13pt", marginTop: "15px" }}>
                        Items Total: {"Ksh. " + totalPrice}
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "13pt", marginTop: "15px" }}>
                        Delivery Cost: {"Ksh. " + deliveryLoc.price}
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "16pt", marginTop: "15px" }}>
                        Total Cost: {"Ksh. " + (Number(totalPrice) + Number(deliveryLoc.price))}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        style={{
                          margin: "7px 0",
                          width: "100%",
                          maxHeight: "250px",
                          overflowY: "auto",
                          borderRadius: "7px",
                        }}
                      >
                        {selected.map((prd, idx) => {
                          return (
                            <MDBox
                              key={idx + "-" + prd._id}
                              style={{
                                fontSize: "13pt",
                                padding: "10px",
                                paddingRight: "10px",
                                backgroundColor: "#efefef",
                                marginBottom: "7px",
                                borderRadius: "7px",
                              }}
                            >
                              <p style={{ width: "100%" }}>{prd.name}</p>
                              <MDBox display={"flex"} justifyContent="space-between">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  {prd.size !== "-" && (
                                    <span style={{ marginRight: "10px" }}>{prd.size}</span>
                                  )}{" "}
                                  {prd.color !== "-" && (
                                    <div
                                      style={{
                                        marginRight: "10px",
                                        backgroundColor: prd.color,
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "inline-block",
                                      }}
                                    ></div>
                                  )}
                                  {prd.option !== "-" && (
                                    <span style={{ fontSize: "13pt" }}>{prd.option}</span>
                                  )}
                                </div>
                                <div>
                                  {"Ksh. " + prd.price * prd.amount}{" "}
                                  <span
                                    style={{
                                      borderLeft: "solid 1px",
                                      margin: "0 15px",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    {prd.amount}
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: "indianred",
                                      color: "white",
                                      textAlign: "center",
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "7px",
                                      display: "inline-block",
                                      marginRight: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeSelected(idx)}
                                  >
                                    {prd.amount > 1 ? "-" : <DeleteForever />}
                                  </span>
                                </div>
                              </MDBox>
                            </MDBox>
                          );
                        })}
                      </MDBox>
                      <FormControl>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          style={{ color: "#202020", fontSize: "13pt" }}
                        >
                          Delivery
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={delivered}
                          onChange={(e) => setDelivered(e.target.value)}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="In-store pickup"
                          />
                          <FormControlLabel value={true} control={<Radio />} label="Delivered" />
                        </RadioGroup>
                      </FormControl>
                      {delivered ? (
                        <fieldset
                          style={{
                            padding: "10px",
                            borderRadius: "7px",
                            width: "100%",
                            position: "relative",
                            boxSizing: "border-box",
                            minInlineSize: "inherit",
                          }}
                        >
                          <legend style={{ fontSize: "13pt" }}>Delivery Details</legend>
                          <Grid container spacing={2} style={{ width: "100%" }}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="county-label">County</InputLabel>
                                <Select
                                  value={deliveryLoc.county}
                                  label="County"
                                  labelId="county-label"
                                  onChange={(e) =>
                                    setDeliveryLoc({
                                      ...deliveryLoc,
                                      subcounty: "",
                                      courier: "",
                                      price: 0,
                                      county: e.target.value,
                                    })
                                  }
                                  style={{ width: "100%", fontSize: "13pt", padding: "10px" }}
                                >
                                  {checkoutInfo.deliveryLocations
                                    .map((dl) => dl.county)
                                    .filter((item, index, arr) => arr.indexOf(item) === index)
                                    .map((cty) => (
                                      <MenuItem value={cty}>{cty}</MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="county-label">Sub-County</InputLabel>
                                <Select
                                  value={deliveryLoc.subcounty}
                                  labelId="subcounty-label"
                                  label="Sub County"
                                  onChange={(e) =>
                                    setDeliveryLoc({
                                      ...deliveryLoc,
                                      courier: "",
                                      price: 0,
                                      subcounty: e.target.value,
                                    })
                                  }
                                  style={{ width: "100%", fontSize: "13pt", padding: "10px" }}
                                >
                                  {checkoutInfo.deliveryLocations
                                    .filter((dl) => dl.county === deliveryLoc.county)
                                    .map((dl) => dl.subcounty)
                                    .filter((item, index, arr) => arr.indexOf(item) === index)
                                    .map((scty) => (
                                      <MenuItem value={scty}>{scty}</MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl fullWidth>
                                <InputLabel id="courier-label">Courier</InputLabel>
                                <Select
                                  value={deliveryLoc.loc}
                                  labelId="courier-label"
                                  label="Courier"
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setDeliveryLoc({
                                      ...deliveryLoc,
                                      courier: e.target.value.courier,
                                      loc: e.target.value,
                                      price: e.target.value.price,
                                    });
                                  }}
                                  style={{ width: "100%", fontSize: "13pt", padding: "10px" }}
                                >
                                  {checkoutInfo.deliveryLocations
                                    .filter(
                                      (dl) =>
                                        dl.county === deliveryLoc.county &&
                                        dl.subcounty === deliveryLoc.subcounty
                                    )
                                    // .filter((dl) => dl.subcounty === deliveryLoc.subcounty)
                                    // .map((dl) => dl.courier)
                                    // .filter((item, index, arr) => arr.indexOf(item) === index)
                                    .map((loc) => (
                                      <MenuItem value={loc}>
                                        {loc.courier + " - " + loc.description}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                label="Delivery Cost"
                                value={deliveryLoc.price}
                                onChange={(e) =>
                                  setDeliveryLoc({ ...deliveryLoc, price: e.target.value })
                                }
                                style={{ width: "100%" }}
                                type="number"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Street/Building/Floor/House No."
                                value={deliveryLoc.specification}
                                onChange={(e) =>
                                  setDeliveryLoc({ ...deliveryLoc, specification: e.target.value })
                                }
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </fieldset>
                      ) : (
                        <div></div>
                      )}
                      <MDInput
                        label="Client Name (Optional)"
                        value={client.name}
                        onChange={(e) => setClient({ ...client, name: e.target.value })}
                        style={{ width: "100%", margin: "10px 0" }}
                        type="text"
                      />
                      <MDInput
                        label="Client Email (Optional)"
                        value={client.email}
                        onChange={(e) => setClient({ ...client, email: e.target.value })}
                        style={{ width: "100%", margin: "10px 0" }}
                        type="email"
                      />
                      <MDInput
                        label="Client Phone Number"
                        value={client.phone}
                        onChange={(e) => setClient({ ...client, phone: e.target.value })}
                        style={{ width: "100%", margin: "10px 0" }}
                      />

                      <br />
                      <MDBox width="100%" fontSize="13pt">
                        <Checkbox
                          checked={client.send}
                          onChange={(e) => setClient({ ...client, send: e.target.checked })}
                        />{" "}
                        Send invoice to client
                      </MDBox>
                      <MDButton
                        size="small"
                        style={{
                          mt: 2,
                          backgroundColor: "green",
                          color: "white",
                          margin: "0 auto",
                        }}
                        onClick={() => sendInvoice()}
                      >
                        {sendingInvoice ? "Sending..." : "Send Invoice"}
                      </MDButton>
                      {sentInvoice && (
                        <span style={{ marginLeft: "10px", fontSize: "13pt", color: "green" }}>
                          Invoice Successfully Sent
                        </span>
                      )}
                    </Grid>
                  </Grid>
                )}
                {active === "pending" &&
                  posInvoices
                    .filter((inv) => inv.status === "INVOICED")
                    .map((inv) => (
                      <PosInvoice invoice={inv} key={inv._id} setPosInvoices={setPosInvoices} />
                    ))}
                <MDBox
                  id="modal-modal-description"
                  variant="text"
                  sx={{ mt: 2, fontSize: "13pt", width: "100%", textAlign: "center" }}
                >
                  {posError && <span style={{ color: "indianred" }}>{posError}</span>}
                </MDBox>
              </MDBox>
            </Modal>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{
                textAlign: "center",
                padding: "10px",
                borderRadius: "7px",
                cursor: "pointer",
              }}
              variant="gradient"
              bgColor="secondary"
              onClick={() => setOpenModal(true)}
            >
              <a
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  display: "block",
                  color: "white",
                }}
                href="/orders"
              >
                Orders {orders > 0 && "(" + orders + ")"}
              </a>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{
                textAlign: "center",
                padding: "10px",
                borderRadius: "7px",
                cursor: "pointer",
              }}
              variant="gradient"
              bgColor="secondary"
              onClick={() => setOpenModal(true)}
            >
              <a
                mb={1.5}
                style={{
                  color: "white",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                href="./billing?section=invoices"
              >
                My Bills {invoices > 0 && "(" + invoices + ")"}
              </a>
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="store"
                  title="Website Visits"
                  count={statistics.visits || statistics.visits == 0 ? statistics.visits : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Website Visits this month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="person_add"
                  title="Registered Users"
                  count={statistics.users || statistics.users == 0 ? statistics.users : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Total Registered Users",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="attach_money"
                  title="Revenue"
                  count={
                    statistics.revenue || statistics.revenue == 0
                      ? "Ksh. " + statistics.revenue
                      : "-"
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Sales made this month",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="shop_two"
                  title="Orders"
                  count={statistics.orders || statistics.orders == 0 ? statistics.orders : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Total Orders This month",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="website visits"
                  description="Trends in website popularity"
                  date="updated Today"
                  chart={visits}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="monthly sales"
                  description="Sales progress"
                  date="updated Today"
                  chart={revenueData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Registered Users"
                  description="Business Growth"
                  date="updated today"
                  chart={growth}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
