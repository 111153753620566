/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

// import { storeData } from "./data/kitchenWareData";
import Product from "./Components/Product/Product";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import MDButton from "components/MDButton";
// import { getCategories } from "redux/features/productsSlice";
// import { getGenderizable } from "redux/features/productsSlice";
// import { getNextBatch } from "redux/features/productsSlice";
import NewProduct from "./Components/NewProduct";
// import { getWearables } from "redux/features/productsSlice";
// import { fetchAccountProfile } from "redux/features/profileSlice";
import { getCategories } from "redux/routes/products";
import { getGenderizable } from "redux/routes/products";
import { getWearables } from "redux/routes/products";
import { fetchAccountProfile } from "redux/routes/profile";
import { useGlobalContext } from "context/context";
import { getAllProducts } from "redux/routes/products";
import AffiliateProducts from "./Components/AffiliateProducts";
import { fetchProduct } from "redux/routes/product";

function Products() {
  // let dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [genderizable, setGenderizable] = useState([]);
  const [wearables, setWearables] = useState([]);
  let { account } = useGlobalContext();

  const init = async () => {
    getCategories().then((res) => {
      if (res.success) setCategories(res.data);
    });
    getGenderizable().then((res) => {
      if (res.success) setGenderizable(res.data);
    });
    getWearables().then((res) => {
      if (res.success) setWearables(res.data);
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    productsLoader();
  }, []);
  const [section, setSection] = useState(1);
  const openSection = (sec) => {
    setSection(sec);
  };

  const [storeData, setStoreData] = useState([]);
  const [prodCount, setProdCount] = useState(storeData.length);
  let productsLoader = async () => {
    let res = await getAllProducts();
    if (res.success) {
      setStoreData(res.data);
    }
  };

  useEffect(() => {
    const incompleteProducts = storeData.filter((p) => p.building); // Get all products still building

    if (incompleteProducts.length > 0) {
      const intervalId = setInterval(async () => {
        const updatedData = await Promise.all(
          storeData.map(async (p) => {
            if (p.building) {
              let res = await fetchProduct(p._id);
              if (res.success && !res.data.building) {
                // Update the product in the array when it completes
                return { ...p, ...res.data };
              }
            }
            return p; // Return the product as is if it's already complete
          })
        );

        setStoreData(updatedData); // Update the storeData with new fetched product data
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Cleanup interval when no more products need polling
    }
  }, [storeData]);

  useEffect(() => {
    setProdCount(storeData.length);
  }, [storeData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={2}>
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            justifyContent: "space-between",
            // marginBottom: "8px",
          }}
        >
          <li
            className=""
            style={{
              padding: "7px",
              paddingBottom: "2px",
              width: "33%",
              textAlign: "center",
              fontSize: "11pt",
              backgroundColor: section === 1 ? "dodgerblue" : "#dbdbdb",
              color: section === 1 ? "white" : "#808080",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              transition: ".5s",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            onClick={() => openSection(1)}
          >
            Add Product
          </li>
          <li
            className="p-2 w-[190px] text-center ml-[3px]"
            style={{
              padding: "7px",
              paddingBottom: "2px",
              width: "33%",
              textAlign: "center",
              fontSize: "11pt",
              backgroundColor: section === 2 ? "dodgerblue" : "#dbdbdb",
              color: section === 2 ? "white" : "#808080",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              transition: ".5s",
              textTransform: "uppercase",
              cursor: "pointer",
              marging: "0 5px",
            }}
            onClick={() => openSection(2)}
          >
            My Products ({prodCount})
          </li>
          <li
            className="p-2 w-[190px] text-center ml-[3px]"
            style={{
              padding: "7px",
              paddingBottom: "2px",
              width: "33%",
              textAlign: "center",
              fontSize: "11pt",
              backgroundColor: section === 3 ? "dodgerblue" : "#dbdbdb",
              color: section === 3 ? "white" : "#808080",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              transition: ".5s",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            onClick={() => openSection(3)}
          >
            Affiliate
          </li>
        </ul>
        {section === 1 && (
          <MDBox
            style={{
              backgroundColor: "#fefefe",
              padding: "5px",
              borderTop: "solid 5px dodgerblue",
            }}
          >
            <NewProduct storeData={storeData} setStoreData={setStoreData} categories={categories} />
          </MDBox>
        )}
        {section == 2 && (
          <MDBox
            style={{
              backgroundColor: "#fefefe",
              padding: "5px",
              borderTop: "solid 5px dodgerblue",
            }}
          >
            {storeData && (
              <Product
                storeData={storeData}
                setProdCount={setProdCount}
                categories={categories}
                profile={account}
              ></Product>
            )}
          </MDBox>
        )}
        {section == 3 && (
          <MDBox
            style={{
              backgroundColor: "#fefefe",
              padding: "5px",
              borderTop: "solid 5px dodgerblue",
            }}
          >
            <AffiliateProducts storeData={storeData} setStoreData={setStoreData} />
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Products;
