import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  FilledInput,
  FormControl,
  Grid,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
// import { updateProfile } from "redux/features/profileSlice";
import MDSnackbar from "components/MDSnackbar";
import { county_subcounty } from "assets/data/counties";
import MapsAddress from "layouts/MapsAddress/MapsAddress";
import {
  Delete,
  DeleteOutline,
  Facebook,
  FacebookOutlined,
  Google,
  Instagram,
  Twitter,
} from "@mui/icons-material";
import { useGlobalContext } from "context/context";
import { updateProfile } from "redux/routes/profile";

function Profile() {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);

  const [isLoading, setIsLoading] = useState(false);
  const { profile, setProfile } = useGlobalContext();

  useEffect(() => {
    if (profile.name && !business.name) {
      let bizz = profile;
      setBusiness(bizz);
    }
  }, [profile]);

  const [business, setBusiness] = useState(profile);
  const [updating, setUpdating] = useState(false);

  // const dispatch = useDispatch();
  let updateItem = async () => {
    setActivity("Update");
    if (!updating) setUpdating(true);
    else if (!isLoading) {
      if (business.icon === null) {
        setErr("Please provide an image");
        openErrorSB();
        return;
      }

      if (
        business.name === "" ||
        business.phone === "" ||
        business.email === "" ||
        business.location === "" ||
        business.about === ""
      ) {
        setErr("Fill in all required fields");
        openErrorSB();
        return;
      }
      if (!/(^\d{10}$)|(^\+\d{12}$)/.test(business.phone)) {
        setErr("Invalid Phone Number. Use 0712345678 or +254712345678");
        openErrorSB();
        return;
      }
      if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(business.email)) {
        setErr("Invalid Email");
        openErrorSB();
        return;
      }
      let count = 0;
      for (var i = 0; i < business.name.length; i++) {
        let ch = business.name.charAt(i).toLowerCase();
        if (/^[a-z]/i.test(ch)) {
          count++;
        }
      }

      if (count < 2) {
        setErr("Invalid Business Name");
        openErrorSB();
        return;
      }

      const fd = new FormData();
      fd.append("icon", business.icon);
      fd.append("phone", business.phone);
      fd.append("name", business.name);
      fd.append("email", business.email);
      fd.append("location", business.location);
      fd.append("about", business.about);
      fd.append("showPrice", business.showPrice);
      fd.append("facebook", business.facebook);
      fd.append("instagram", business.instagram);
      fd.append("twitter", business.twitter);
      fd.append("google", business.google);
      fd.append("aboutDetailed", business.aboutDetailed);
      fd.append("responseTimeUnit", business.responseTime.unit);
      fd.append("responseTimeAmount", business.responseTime.amount);
      fd.append("workingHours", business.workingHours);
      setIsLoading(true);
      let res = await updateProfile(fd);
      if (res.success) {
        setProfile(res.data);
      }

      setUpdating(false);
      setIsLoading(false);

      openSuccessSB();
    }
  };

  let cancelUpdating = () => {
    setUpdating(false);
    setBusiness(profile);
  };
  const isFile = (input) => "File" in window && input instanceof File;
  const getURL = (image) => {
    let url = URL.createObjectURL(image);
    return url;
  };

  let [activity, setActivity] = useState("");
  const setLocation = (val) => {
    setBusiness({ ...business, location: val });
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={activity.slice(0, 6) + " Profile"}
      content="Profile Successfully Updated"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );
  let [err, setErr] = useState("");
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={activity.slice(0, 6) + " Profile"}
      content={err}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  let [warning, setWarning] = useState("");
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      id="warning"
      title={" Profile"}
      content={warning}
      dateTime="1 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgindianred="true"
    />
  );

  let notUpdating = (event) => {
    let id = event.target.id;
    if (!updating && id !== "edit-btn" && id !== "warning") {
      setWarning("To start editing. Click on the green pencil button");
      openWarningSB();
    }
  };
  // let till = useSelector((state) => state.profile.mpesaTill);
  // let paybill = useSelector((state) => state.profile.mpesaPaybill);
  const { mpesaPaybill, mpesaTill } = useGlobalContext();
  let setShowPrice = (e) => {
    if (e.target.checked) {
      if (JSON.stringify(mpesaTill) === "{}" && JSON.stringify(mpesaPaybill) === "{}") {
        setErr("You need to have at least one payment option available in order to show prices");
        openErrorSB();
        return;
      }
      setBusiness({ ...business, showPrice: e.target.checked });
    } else {
      setBusiness({ ...business, showPrice: e.target.checked });
    }
  };

  return (
    <>
      <MDBox
        minWidth="320px"
        borderRadius="10px"
        display="flex"
        flexDirection="column"
        justify="center"
        alignItems="center"
        className="profile"
        m={2}
        p={1}
        style={{ boxShadow: "0 0 5px grey" }}
        onClick={notUpdating}
      >
        <MDBox display="flex" width="100%" justifyContent="space-evenly" flexWrap="wrap">
          <MDTypography width="100%">Profile Information</MDTypography>
          <MDBox minWidth="150px" width="100%" display="flex" mr={1} justifyContent="space-between">
            <MDBox>
              <MDButton
                style={{ backgroundColor: "green", color: "white" }}
                onClick={updateItem}
                id="edit-btn"
              >
                {isLoading ? "..." : <Icon id="edit-btn">{updating ? "done" : "edit"}</Icon>}
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                disabled={!updating}
                style={{ backgroundColor: "goldenrod", color: "white" }}
                onClick={cancelUpdating}
              >
                <Icon>cancel</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          minWidth="320px"
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Grid container spacing={2}>
            {/* <MDBox
            minWidth="320px"
            width="47%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          > */}
            <Grid item xs={12} md={6}>
              <MDBox width="100%" pt={2} textAlign="center" display="flex" justifyContent="center">
                <img
                  src={isFile(business.icon) ? getURL(business.icon) : business.icon}
                  style={{ maxWidth: "100px" }}
                  height="80px"
                  className={business.icon === profile.icon ? "" : "input-modified"}
                />
                <MDBox width="200px" pl={2} textAlign="left">
                  <Typography variant="body" style={{ fontSize: "10pt" }}>
                    {" "}
                    Update Icon{" "}
                  </Typography>
                  <MDInput
                    size="small"
                    type="file"
                    display="none"
                    id="icon-select"
                    style={{ pointerEvents: updating ? "initial" : "none" }}
                    onChange={(e) => {
                      setBusiness({ ...business, icon: e.target.files[0] });
                    }}
                  />
                </MDBox>
              </MDBox>
              <MDBox width="100%" pt={2} textAlign="center">
                <TextField
                  size="small"
                  label="Name"
                  value={business.name || ""}
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                  className={business.name === profile.name ? "" : "input-modified"}
                  onChange={(e) => {
                    setBusiness({ ...business, name: e.target.value });
                  }}
                />
              </MDBox>
              <MDBox width="100%" pt={2} textAlign="center">
                <TextField
                  size="small"
                  label="Phone"
                  value={business.phone || ""}
                  inputProps={{ maxLength: 20 }}
                  type="text"
                  style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                  className={business.phone === profile.phone ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, phone: e.target.value })}
                />
              </MDBox>
              <MDBox width="100%" pt={2} textAlign="center">
                <TextField
                  size="small"
                  label="Email"
                  value={business.email || ""}
                  inputProps={{ maxLength: 40 }}
                  type="text"
                  style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                  className={business.email === profile.email ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, email: e.target.value })}
                />
              </MDBox>
              <MDBox width="100%" pt={2} textAlign="center" overflow-y="scroll">
                <MapsAddress
                  label="Location"
                  value={business.location || ""}
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  setLocation={setLocation}
                  oldVal={profile.location}
                  className={business.location === profile.location ? "" : "input-modified"}
                />
              </MDBox>
            </Grid>
            {/* </MDBox> */}

            <Grid item xs={12} md={6}>
              {/* <MDBox minWidth="320px" width="47%" display="flex" flexDirection="column"> */}
              <MDBox
                width="100%"
                pt={2}
                textAlign="center"
                style={{ overflowY: "auto", maxHeight: "100px" }}
              >
                <TextField
                  size="small"
                  label="Business Summary"
                  multiline={true}
                  value={business.about || ""}
                  inputProps={{ maxLength: 500 }}
                  type="text"
                  style={{ width: "100%", pointerEvents: updating ? "initial" : "none" }}
                  className={business.about === profile.about ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, about: e.target.value })}
                />
              </MDBox>
              <MDBox width="100%" pt={2} pl={1}>
                <Typography variant="body" style={{ fontSize: "10pt" }}>
                  {" "}
                  Show Price{" "}
                </Typography>
                <Switch
                  checked={
                    (business.showPrice &&
                      !(
                        JSON.stringify(mpesaPaybill) === "{}" && JSON.stringify(mpesaTill) === "{}"
                      )) ||
                    false
                  }
                  style={{ pointerEvents: updating ? "initial" : "none" }}
                  className={business.showPrice === profile.showPrice ? "" : "input-modified"}
                  onChange={(e) => {
                    setShowPrice(e);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Show Price"
                />
                <MDBox
                  style={{
                    width: "80%",
                    margin: "0 auto",
                    marginTop: "10px",
                    borderRadius: "5px",
                    padding: "10px",
                    border: "solid 1px #909090",
                  }}
                >
                  <Typography variant="caption">
                    {business.showPrice
                      ? "Clients will be able to check out and pay before submitting an order"
                      : "Clients will submit an order of goods they are interested in and you will communicate the price back to them"}
                  </Typography>
                </MDBox>
              </MDBox>
              <MDBox width="100%" pt={2} pl={1} style={{ display: "flex", alignItems: "center" }}>
                <Facebook />
                &nbsp;&nbsp;
                <Typography variant="body" style={{ fontSize: "10pt", width: "80px" }}>
                  Facebook{" "}
                </Typography>
                <TextField
                  style={{
                    width: "auto",
                    borderRadius: "5px",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  size="small"
                  label="Facebook Link"
                  value={
                    business.facebook && business.facebook !== "undefined" ? business.facebook : ""
                  }
                  className={business.facebook === profile.facebook ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, facebook: e.target.value })}
                />
                &nbsp;&nbsp;
                <Delete
                  style={{ color: "indianred", cursor: "pointer" }}
                  onClick={() => setBusiness({ ...business, facebook: "" })}
                />
              </MDBox>

              <MDBox width="100%" pt={2} pl={1} style={{ display: "flex", alignItems: "center" }}>
                <Twitter />
                &nbsp;&nbsp;
                <Typography variant="body" style={{ fontSize: "10pt", width: "80px" }}>
                  Twitter{" "}
                </Typography>
                <TextField
                  style={{
                    width: "auto",
                    borderRadius: "5px",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  size="small"
                  label="Twitter Link"
                  value={
                    business.twitter && business.twitter !== "undefined" ? business.twitter : ""
                  }
                  className={business.twitter === profile.twitter ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, twitter: e.target.value })}
                />
                &nbsp;&nbsp;
                <Delete
                  style={{ color: "indianred", cursor: "pointer" }}
                  onClick={() => setBusiness({ ...business, twitter: "" })}
                />
              </MDBox>
              <MDBox width="100%" pt={2} pl={1} style={{ display: "flex", alignItems: "center" }}>
                <Instagram />
                &nbsp;&nbsp;
                <Typography variant="body" style={{ fontSize: "10pt", width: "80px" }}>
                  Instagram{" "}
                </Typography>
                <TextField
                  style={{
                    width: "auto",
                    borderRadius: "5px",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  size="small"
                  label="Instagram Link"
                  value={
                    business.instagram && business.instagram !== "undefined"
                      ? business.instagram
                      : ""
                  }
                  className={business.instagram === profile.instagram ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, instagram: e.target.value })}
                />
                &nbsp;&nbsp;
                <Delete
                  style={{ color: "indianred", cursor: "pointer" }}
                  onClick={() => setBusiness({ ...business, instagram: "" })}
                />
              </MDBox>

              <MDBox width="100%" pt={2} pl={1} style={{ display: "flex", alignItems: "center" }}>
                <Google />
                &nbsp;&nbsp;
                <Typography variant="body" style={{ fontSize: "10pt", width: "80px" }}>
                  Google{" "}
                </Typography>
                <TextField
                  style={{
                    width: "auto",
                    borderRadius: "5px",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  size="small"
                  label="Google Business Link"
                  value={business.google && business.google !== "undefined" ? business.google : ""}
                  className={business.google === profile.google ? "" : "input-modified"}
                  onChange={(e) => setBusiness({ ...business, google: e.target.value })}
                />
                &nbsp;&nbsp;
                <Delete
                  style={{ color: "indianred", cursor: "pointer" }}
                  onClick={() => setBusiness({ ...business, google: "" })}
                />
              </MDBox>
              {/* </MDBox> */}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <MDBox minWidth="320px" width="47%" display="flex" flexDirection="column"> */}
              <MDBox width="100%" pt={2} pl={1}>
                <Typography style={{ fontSize: "12pt", marginBottom: "15px" }}>
                  How long does it take you to respond to a message from the client?
                </Typography>
                <FormControl width="45%">
                  <InputLabel id="demo-simple-select-label">Response Time</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={business.responseTime ? business.responseTime.unit : "days"}
                    label="Response Time"
                    style={{
                      width: "150px",
                      padding: "8px",
                      pointerEvents: updating ? "initial" : "none",
                    }}
                    onChange={(e) =>
                      setBusiness({
                        ...business,
                        responseTime: { ...business.responseTime, unit: e.target.value },
                      })
                    }
                  >
                    <MenuItem value={"hours"}>Hours</MenuItem>
                    <MenuItem value={"days"}>Days</MenuItem>
                    <MenuItem value={"weeks"}>Weeks</MenuItem>
                    <MenuItem value={"months"}>Months</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Value"
                  size="small"
                  value={business.responseTime ? business.responseTime.amount : ""}
                  onChange={(e) =>
                    setBusiness({
                      ...business,
                      responseTime: { ...business.responseTime, amount: e.target.value },
                    })
                  }
                  style={{ marginLeft: "20px", pointerEvents: updating ? "initial" : "none" }}
                />
              </MDBox>
              {/* </MDBox> */}
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <MDBox minWidth="320px" width="47%" display="flex" flexDirection="column"> */}
              <MDBox width="100%" pt={2} pl={1}>
                <Typography style={{ fontSize: "12pt", marginBottom: "15px" }}>
                  Select your working hours
                </Typography>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ marginBottom: "10px", marginLeft: "10px" }}
                >
                  Working Hours
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={business.workingHours ? business.workingHours : ""}
                  label="Working Hours"
                  style={{
                    width: "100%",
                    minWidth: "200px",
                    padding: "8px",
                    pointerEvents: updating ? "initial" : "none",
                  }}
                  onChange={(e) =>
                    setBusiness({
                      ...business,
                      workingHours: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"Monday - Saturday, 8AM - 8PM"}>
                    Monday - Saturday, 8AM - 8PM
                  </MenuItem>
                  <MenuItem value={"Monday - Saturday, 8AM - 8PM, Sundays 2PM - 7PM"}>
                    Monday - Saturday, 8AM - 8PM, Sundays 2PM - 7PM
                  </MenuItem>
                  <MenuItem value={"Monday - Friday, 8AM - 5PM, Weekends 9AM - 4PM"}>
                    Monday - Friday, 8AM - 5PM, Weekends 9AM - 4PM
                  </MenuItem>
                  <MenuItem value={"Monday - Friday, 8AM - 7PM, Weekends 11AM - 6PM"}>
                    Monday - Friday, 8AM - 7PM, Weekends 11AM - 6PM
                  </MenuItem>
                </Select>
              </MDBox>
              {/* </MDBox> */}
            </Grid>

            {/* <MDBox minWidth="320px" width="97%" marginTop="20px"> */}
            <Grid item xs={12}>
              <TextField
                size="small"
                label="About Business"
                multiline={true}
                value={business.aboutDetailed || ""}
                inputProps={{ maxLength: 5000 }}
                type="text"
                style={{
                  width: "100%",
                  pointerEvents: updating ? "initial" : "none",
                  marginTop: "30px",
                }}
                className={business.aboutDetailed === profile.aboutDetailed ? "" : "input-modified"}
                onChange={(e) => setBusiness({ ...business, aboutDetailed: e.target.value })}
              />
            </Grid>
            {/* </MDBox> */}
          </Grid>
        </MDBox>
      </MDBox>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
    </>
  );
}

export default Profile;
