import MDBox from "components/MDBox";
import React, { useEffect, useState, useRef } from "react";
import "../css/product.css";

import MDSnackbar from "components/MDSnackbar";
import { getAffiliateProducts } from "redux/routes/products";
import { Button, Grid, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { copyAffiliateProduct } from "redux/routes/products";

function AffiliateProducts({ setStoreData }) {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [batchNo, setBatchNo] = useState(0);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true); // For determining if more products are available
  const productContainerRef = useRef(null); // Ref for the product scroll container

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const [error, setError] = useState("");

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Product"
      content={"Product Successfully " + "loaded"}
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={error}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      id="warning"
      title="Product"
      content="Something went wrong"
      dateTime="1 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgindianred="true"
    />
  );

  // Function to fetch the next batch of products
  const fetchNextBatch = async (batchNoOverride) => {
    if (fetching || !hasMore) return; // Prevent additional fetches if already fetching or no more data

    setFetching(true);
    let res = await getAffiliateProducts({
      batchNo: batchNoOverride !== undefined ? batchNoOverride : batchNo,
      search,
    });

    if (res.success) {
      // If batch returns empty, no more data
      if (res.data.length === 0) {
        setHasMore(false);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...res.data]); // Append new products
      }
      setBatchNo((prevBatchNo) => prevBatchNo + 1); // Increase the batch number
    } else {
      setError("Unable to fetch products");
      openErrorSB();
    }
    setFetching(false);
  };

  // Fetch the first batch when component mounts
  useEffect(() => {
    if (batchNo === 0) fetchNextBatch(0);
  }, []);

  // Fetch filtered products when search is applied
  const applySearch = async () => {
    setHasMore(true); // Reset the infinite scroll condition
    setProducts([]); // Clear current products
    fetchNextBatch(0); // Fetch the first batch of filtered products
  };

  // Function to handle scroll event and detect when the user reaches the bottom
  const handleScroll = () => {
    if (!productContainerRef.current || fetching || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = productContainerRef.current;

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      // Fetch more products when reaching near the bottom
      fetchNextBatch();
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const container = productContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetching, hasMore]);

  const resizeCardImage = (img) => {
    img = img.replace(
      "https://storage.googleapis.com/test-bucket001/",
      "https://ik.imagekit.io/d4mmlivtj/goduka/tr:w-900/"
    );
    return img;
  };

  const [copying, setCopying] = useState([]);
  const addToMyList = async (_id) => {
    if (copying.includes(_id)) return;
    setCopying([...copying, _id]);
    let res = await copyAffiliateProduct({ origId: _id });
    if (res.success) {
      setStoreData((prev) => [...prev, res.data]);
      setProducts(products.filter((p) => p._id !== _id));
    }
    setCopying(copying.filter((id) => id != _id));
  };

  useEffect(() => {
    console.log(copying);
  }, [copying]);

  return (
    <>
      <MDBox
        minWidth="320px"
        borderRadius="10px"
        className="product"
        m={2}
        p={1}
        style={{ boxShadow: "0 0 5px grey" }}
      >
        {/* Filters */}
        <MDBox display="flex" flexWrap="wrap" justifyContent="center">
          <TextField
            label="Search"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && applySearch()} // Apply search on Enter
          />
        </MDBox>

        {/* Product List */}
        <MDBox
          mt={1}
          p={1}
          ref={productContainerRef} // Attach ref to the product list container
          style={{
            backgroundColor: "#eee",
            borderRadius: "5px",
            border: "solid 1px #bbb",
            overflowY: "auto",
            maxHeight: "70vh",
          }}
        >
          {products.length === 0 ? (
            fetching ? (
              <div>Fetching products...</div>
            ) : (
              <div>No products found.</div>
            )
          ) : (
            products
              .filter((p) => p.owner)
              .map((product, index) => {
                let url = product.owner.customUrl ? product.owner.customUrl : product.owner.url;
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <img
                        src={resizeCardImage(product.img)}
                        style={{ width: "100%", aspectRatio: "4 / 3" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <MDBox
                        key={product._id + "-" + index}
                        style={{ height: "100px", padding: "7px", flexDirection: "column" }}
                        display="flex"
                      >
                        <div>
                          <a
                            href={url + "/filter/item/" + product._id}
                            style={{ color: "#202020", fontSize: "12pt" }}
                          >
                            {product.name}
                          </a>
                        </div>
                        <div style={{ fontSize: "10pt" }}>
                          Price: Ksh. {product.price + " "}{" "}
                          <span>Commission: Ksh. {product.commission}</span>
                        </div>
                        <div style={{ fontSize: "10pt" }}>
                          Owner:
                          <a href={url} style={{ color: "#344767" }}>
                            {" " + product.owner.name + " "}
                          </a>
                        </div>
                        <div>
                          {product.affiliateVisibility === "public" ? (
                            <MDButton
                              padding="4px"
                              style={{
                                backgroundColor: "dodgerblue",
                                color: "white",
                              }}
                              onClick={() => addToMyList(product._id)}
                            >
                              {copying.includes(product._id) ? "Adding..." : "Add To My List"}
                            </MDButton>
                          ) : (
                            <MDButton
                              style={{
                                backgroundColor: "dodgerblue",
                                color: "white",
                              }}
                              padding="4px"
                              onClick={() => addToMyList(product._id)}
                            >
                              {copying.includes(product._id) ? "Adding..." : "Add To My List"}
                            </MDButton>
                            // <MDButton
                            //   size="small"
                            //   style={{
                            //     backgroundColor: "dodgerblue",
                            //     color: "white",
                            //   }}
                            // >
                            //   Request To Add
                            // </MDButton>
                          )}
                        </div>
                      </MDBox>
                    </Grid>
                  </Grid>
                );
              })
          )}
          {fetching && <div>Loading more products...</div>}
        </MDBox>
      </MDBox>

      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
    </>
  );
}

export default AffiliateProducts;
