import axios from "../axios.config";

export const updateProduct = async (payload) => {
  try {
    let res = await axios.post("/product/update", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fetchProduct = async (payload) => {
  try {
    let res = await axios.get("/product/" + payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fetchProductAffiliates = async (payload) => {
  try {
    let res = await axios.get("/product/affiliates/" + payload.pid);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteProduct = async (payload) => {
  try {
    let res = await axios.post("/product/delete/" + payload._id);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const addFeature = async (payload) => {
  try {
    return await axios.post("/product/add-feature/", payload);
  } catch (err) {
    return err;
  }
};
