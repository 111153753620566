import axios from "../axios.config";

export const fetchFullProfile = async () => {
  try {
    let res = await axios.get("/profile");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fetchAccountProfile = async (payload) => {
  try {
    let res = await axios.get("/profile/account");
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateProfile = async (payload) => {
  try {
    let res = await axios.post("/profile/update", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateMpesaTill = async (payload) => {
  try {
    let res = await axios.post("/profile/update/mpesatill", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateMpesaPaybill = async (payload) => {
  try {
    let res = await axios.post("/profile/update/mpesapaybill", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteMpesaTill = async (payload) => {
  try {
    let res = await axios.post("/profie/delete/mpesatill", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteMpesaPaybill = async (payload) => {
  try {
    let res = await axios.post("/profile/delete/mpesapaybill", payload);
    return res.data;
  } catch (err) {
    return err;
  }
};
