/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { getOrders } from "redux/features/ordersSlice";
import { TextField, Tooltip, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import "./css/styles.css";
import MDModal from "components/MDModal/MDModal";
import { Description } from "@mui/icons-material";
import OrdersList from "./components/Orders";
import { getOrders } from "redux/routes/orders";

function Orders() {
  const [newOrders, setNewOrders] = useState([]);
  const [pending, setPending] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [refunded, setRefunded] = useState([]);

  // let dispatch = useDispatch();
  const init = async () => {
    let res = await getOrders();
    if (res.success) {
      setNewOrders(res.data.newOrders);
      setPending(res.data.pending);
      setDelivered(res.data.delivered);
      setRefunded(res.data.refunded);
    }
  };
  useEffect(() => {
    // dispatch(getOrders());
    init();
  }, []);

  const [section, setSection] = useState(1);
  const openSection = (sec) => {
    setSection(sec);
  };

  const updateOrders = (order) => {
    setNewOrders(
      newOrders.map((o) => {
        if (o.oid === order.oid) {
          o.products = o.products.map((item) => {
            if (item._id.toString() === order.opid) {
              item.status = order.status;
            }
            return item;
          });
        }
        return o;
      })
    );
    setPending(
      pending.map((o) => {
        if (o.oid === order.oid) {
          o.products = o.products.map((item) => {
            if (item._id.toString() === order.opid) {
              item.status = order.status;
            }
            return item;
          });
        }
        return o;
      })
    );
    setDelivered(
      delivered.map((o) => {
        if (o.oid === order.oid) {
          o.products = o.products.map((item) => {
            if (item._id.toString() === order.opid) {
              item.status = order.status;
            }
            return item;
          });
        }
        return o;
      })
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
              </MDBox> */}
              <ul
                style={{
                  listStyle: "none",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <li
                  className=""
                  style={{
                    padding: "7px",
                    paddingBottom: "2px",
                    width: "24.6%",
                    textAlign: "center",
                    fontSize: "11pt",
                    backgroundColor: section === 1 ? "dodgerblue" : "#dbdbdb",
                    color: section === 1 ? "white" : "#808080",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    transition: ".5s",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  onClick={() => openSection(1)}
                >
                  NEW ({newOrders.length})
                </li>
                <li
                  className="p-2 w-[190px] text-center ml-[3px]"
                  style={{
                    padding: "7px",
                    paddingBottom: "2px",
                    width: "24.6%",
                    textAlign: "center",
                    fontSize: "11pt",
                    backgroundColor: section === 2 ? "dodgerblue" : "#dbdbdb",
                    color: section === 2 ? "white" : "#808080",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    transition: ".5s",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  onClick={() => openSection(2)}
                >
                  PENDING ({pending.length})
                </li>
                <li
                  className="p-2 w-[190px] text-center ml-[3px]"
                  style={{
                    padding: "7px",
                    paddingBottom: "2px",
                    width: "24.6%",
                    textAlign: "center",
                    fontSize: "11pt",
                    backgroundColor: section === 3 ? "dodgerblue" : "#dbdbdb",
                    color: section === 3 ? "white" : "#808080",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    transition: ".5s",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  onClick={() => openSection(3)}
                >
                  DELIVERED ({delivered.length})
                </li>
                <li
                  className="p-2 w-[190px] text-center ml-[3px]"
                  style={{
                    padding: "7px",
                    paddingBottom: "2px",
                    width: "24.6%",
                    textAlign: "center",
                    fontSize: "11pt",
                    backgroundColor: section === 4 ? "dodgerblue" : "#dbdbdb",
                    color: section === 4 ? "white" : "#808080",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    transition: ".5s",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  onClick={() => openSection(4)}
                >
                  REFUNDED ({refunded.length})
                </li>
              </ul>
              {section === 1 && (
                <MDBox style={{ borderTop: "solid 5px dodgerblue" }}>
                  <OrdersList orders={newOrders} modifiable={true} updateOrders={updateOrders} />
                </MDBox>
              )}
              {section === 2 && (
                <MDBox style={{ borderTop: "solid 5px dodgerblue" }}>
                  <OrdersList orders={pending} modifiable={true} updateOrders={updateOrders} />
                </MDBox>
              )}
              {section == 3 && (
                <MDBox style={{ borderTop: "solid 5px dodgerblue" }}>
                  <OrdersList orders={delivered} modifiable={true} updateOrders={updateOrders} />
                </MDBox>
              )}
              {section == 4 && (
                <MDBox style={{ borderTop: "solid 5px dodgerblue" }}>
                  <OrdersList orders={refunded} modifiable={false} />{" "}
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Orders;
