import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import "../../css/product.css";
import { Checkbox, Grid, Icon, TextField, Typography } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { MDDialog } from "components/MDDialog";
import {
  FilterList,
  FilterListOff,
  AddAPhoto,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowUp,
  KeyboardDoubleArrowDown,
  Create,
  DoneOutline,
  Add,
  Cancel,
  Undo,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import axios from "../../../../redux/axios.config";
import { fetchProductAffiliates } from "redux/routes/product";

export default function ProSellers({ prod, updating }) {
  const [fetching, setFetching] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  useEffect(() => {
    const fetchAffiliates = async () => {
      setFetching(true);
      let res = await fetchProductAffiliates({ pid: prod._id });
      if (res.success) {
        setAffiliates(res.data);
      }
      setFetching(false);
    };

    fetchAffiliates();
  }, []);

  const revoke = async () => {};

  return (
    <MDBox
      style={{
        backgroundColor: "#dfdfdf80",
        padding: "15px",
        margin: "5px 0px",
        borderRadius: "7px",
      }}
    >
      <Typography fontWeight={"bold"}>My Affiliates</Typography>
      <MDBox>
        <MDBox
          style={{
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#dddddd",
            borderRadius: "7px",
            marginTop: "15px",
            maxHeight: "300px",
          }}
        >
          {affiliates.map((aff, idx) => {
            return (
              <Grid
                container
                spacing={2}
                // padding="7px"
                key={idx + "_" + aff._id}
                style={{ borderBottom: "solid 1px #cccccc", fontSize: "11pt" }}
              >
                <Grid item xs={5}>
                  <a href={aff.url}>{aff.name}</a>
                </Grid>
                <Grid item xs={4}>
                  {aff.viewsThisMonth}
                </Grid>
                <Grid item xs={3}>
                  <Icon
                    onClick={() => revoke(aff._id)}
                    style={{
                      cursor: "pointer",
                      color: "indianred",
                      pointerEvents: updating ? "initial" : "none",
                    }}
                  >
                    Revoke
                  </Icon>
                </Grid>
              </Grid>
            );
          })}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
