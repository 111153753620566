/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";
import Template from "./components/Template";
import { useEffect, useState } from "react";
// import { getTemplates } from "redux/features/templatesSlice";
import { Switch } from "@mui/material";
// import { setHolidayTheme } from "redux/features/templatesSlice";
import MDSnackbar from "components/MDSnackbar";
import { getTemplates } from "redux/routes/templates";
import { setHolidayTheme } from "redux/routes/templates";

function Templates() {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const init = async () => {
    let res = await getTemplates();
    if (res.success) {
      setTemplates(res.data.templates);
      setSelectedTemplate(res.data.selectedTemplate);
      setSelectedTheme(res.data.selectedTheme);
      setmHolidayTheme(res.data.holidayTheme);
    }
  };
  useEffect(() => {
    // dispatch(getTemplates());
    init();
  }, []);
  const [templates, setTemplates] = useState([]);
  const [holidayTheme, setmHolidayTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const toggleHolidayThemes = async () => {
    let res = await setHolidayTheme({ holidayTheme: !holidayTheme });

    if (res.success) {
      setmHolidayTheme(res.data.holidayTheme);
    }
    openSuccessSB();
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={"Success"}
      content="Successfully Updated Holiday Themes"
      dateTime="just now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bggreen="true"
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={"Error"}
      content={"Failed To Update Holiday Themes"}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgindianred="true"
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Templates
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
                <MDBox px={3}>
                  Holiday Themes{" "}
                  <Switch
                    checked={holidayTheme ? holidayTheme : false}
                    onChange={(e) => {
                      toggleHolidayThemes(e);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    label="Holiday Themes"
                  />
                </MDBox>
                {templates.map((template, index) => (
                  <Template
                    template={template}
                    key={index + "-" + template.name}
                    setSelectedTemplate={setSelectedTemplate}
                    selectedTheme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                    selectedTemplate={selectedTemplate}
                  />
                ))}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Templates;
