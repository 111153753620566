/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

import { useEffect, useState } from "react";
import { TextField, Tooltip, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import "./css/styles.css";
import MDModal from "components/MDModal/MDModal";
import { Description } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { resetPassword } from "redux/routes/auth";

function PassReset({ token }) {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);
  const [resetting, setResetting] = useState(false);
  let handleReset = async () => {
    if (resetting) {
      return;
    }
    setError("");
    if (password.length < 6) {
      setError("Password needs to be a minimum of 6 characters");
      return;
    }
    if (password !== cpassword) {
      setError("Passwords do not match!");
      return;
    }
    setResetting(true);
    let res = await resetPassword({ password, token });
    if (res.success) {
      setDone(true);
    } else if (res.message) {
      setError(res.message);
    } else {
      setError("An error occured. Please try again after some time");
    }
    setResetting(false);
  };

  return (
    <MDBox
      pt={6}
      pb={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ width: "100%", height: "100vh" }}
    >
      {done ? (
        <MDBox
          p={"10px"}
          style={{ boxShadow: "0 0 2px grey", borderRadius: "5px", textAlign: "center" }}
        >
          <Typography variant="h5">Your password for {} has been reset successfully</Typography>
          <Typography>
            You can proceed to{" "}
            <a
              href="https://admin.go-duka.com"
              style={{ color: "dodgerblue", textDecoration: "underline" }}
            >
              login
            </a>{" "}
            using the new password
          </Typography>
        </MDBox>
      ) : (
        <MDBox
          p={"10px"}
          style={{ boxShadow: "0 0 2px grey", borderRadius: "5px", textAlign: "center" }}
        >
          <Typography variant="h5">Reset Password</Typography>
          <MDBox m="7px" width="300px">
            <MDInput
              type="password"
              value={password}
              onChange={(e) => {
                setError("");
                setPassword(e.target.value);
              }}
              label="Enter new password"
              style={{ width: "100%" }}
            ></MDInput>
          </MDBox>
          <MDBox m="10px" width="300px">
            <MDInput
              type="password"
              value={cpassword}
              onChange={(e) => {
                setError(""), setCPassword(e.target.value);
              }}
              label="Repeat password"
              style={{ width: "100%" }}
            ></MDInput>
          </MDBox>
          <MDBox textAlign="center">
            {error && (
              <MDTypography
                variant="button"
                fontWeight="light"
                style={{
                  backgroundColor: "indianRed",
                  padding: "7px 10px",
                  width: "100%",
                  color: "white",
                  borderRadius: "4px",
                }}
              >
                {error}
              </MDTypography>
            )}
          </MDBox>
          <MDBox m="10px" width="300px">
            <MDButton
              onClick={handleReset}
              style={{ color: "white", backgroundColor: "dodgerblue" }}
            >
              {resetting ? "Resetting..." : "Reset"}
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

export default PassReset;
