/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "layouts/Footer";

import Profile from "./components/profile";
import Till from "./components/Till";
import PayBill from "./components/PayBill";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Deliveries from "./components/Deliveries";
import DisplayDeliveries from "./components/DisplayDeliveries";
import Policies from "./components/Policies";
import { useGlobalContext } from "context/context";
import { getDeliveries } from "redux/routes/delivery";

function Overview() {
  const [active, setActive] = useState("profile");
  const [locations, setLocations] = useState([]);
  const init = async () => {
    let res = await getDeliveries();
    if (res.success) {
      setLocations(res.data);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={2}>
        <Card>
          <ul style={{ display: "flex", justifyContent: "space-between", listStyle: "none" }}>
            <li
              onClick={() => setActive("profile")}
              style={{
                backgroundColor: active === "profile" ? "dodgerblue" : "#dbdbdb",
                color: active === "profile" ? "white" : "#808080",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                cursor: "pointer",
                width: "33%",
                textAlign: "center",
              }}
            >
              Profile
            </li>
            <li
              onClick={() => setActive("shipping")}
              style={{
                backgroundColor: active === "shipping" ? "dodgerblue" : "#dbdbdb",
                color: active === "shipping" ? "white" : "#808080",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                cursor: "pointer",
                width: "33%",
                textAlign: "center",
              }}
            >
              Shipping
            </li>
            <li
              onClick={() => setActive("payments")}
              style={{
                backgroundColor: active === "payments" ? "dodgerblue" : "#dbdbdb",
                color: active === "payments" ? "white" : "#808080",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                cursor: "pointer",
                width: "33%",
                textAlign: "center",
              }}
            >
              Payments
            </li>
          </ul>
          <MDBox>
            {active === "profile" && (
              <MDBox style={{ borderTop: "solid 5px dodgerblue" }}>
                <Profile />
                <Policies />
              </MDBox>
            )}
            {active === "shipping" && (
              <MDBox>
                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: "10px",
                    borderTop: "solid 5px dodgerblue",
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <Deliveries deliveries={locations} setLocations={setLocations} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DisplayDeliveries setLocations={setLocations} locations={locations} />
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {active === "payments" && (
              <Grid
                container
                spacing={2}
                style={{
                  padding: "10px",
                  borderTop: "solid 5px dodgerblue",
                  width: "100%",
                  margin: "0",
                }}
              >
                <Grid item xs={12} md={6}>
                  <Till />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PayBill />
                </Grid>
              </Grid>
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
